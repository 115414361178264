export function pathOrg(pathParams: { orgId: string } = { orgId: ':orgId' }) {
  return `/org/${pathParams.orgId ?? ':orgId'}`;
}

export function pathManagement(pathParams?: { orgId: string }) {
  return `${pathOrg(pathParams)}/management`;
}
export function pathManagementProject(pathParams?: {
  orgId: string;
  projectId: string;
}) {
  return `${pathManagement(pathParams)}/project/${pathParams?.projectId ?? ':projectId'}`;
}
export function pathOperationsHub(pathParams?: { orgId: string }) {
  return `${pathOrg(pathParams)}/operations-hub`;
}
export function pathOperationsHubRoute(pathParams?: {
  orgId: string;
  routeId: string;
}) {
  return `${pathOperationsHub(pathParams)}/route/${pathParams?.routeId ?? ':routeId'}`;
}

export function pathReporting(pathParams?: { orgId: string }) {
  return `${pathOrg(pathParams)}/reporting`;
}

export function pathOrgSettings(pathParams?: { orgId: string }) {
  return `${pathOrg(pathParams)}/settings`;
}
export function pathOrgTeamSettings(pathParams?: { orgId: string }) {
  return `${pathOrgSettings(pathParams)}/team`;
}
export function pathOrgSettingsAccount(pathParams?: { orgId: string }) {
  return `${pathOrgSettings(pathParams)}/account`;
}
export function pathOrgSettingsProfile(pathParams?: { orgId: string }) {
  return `${pathOrgSettings(pathParams)}/profile`;
}
export function pathOrgTeamFragmentSettings(pathParams?: {
  orgId: string;
  fragment: string;
}) {
  return `${pathOrgTeamSettings(pathParams)}/${pathParams?.fragment ?? ':sectionId'}`;
}

export function pathOrgUserSettings(pathParams?: { orgId: string }) {
  return `${pathOrgSettings(pathParams)}/profile`;
}

export function pathSiteMonitoringIndex(pathParams?: { orgId: string }) {
  return `${pathOrg(pathParams)}/site`;
}

export function pathSiteMonitoring(pathParams?: {
  orgId: string;
  siteId: string;
}) {
  return `${pathOrg(pathParams)}/site/${pathParams?.siteId ?? ':siteId'}`;
}
export function pathSiteMonitoringPlanning(pathParams?: {
  orgId: string;
  siteId: string;
}) {
  return `${pathSiteMonitoring(pathParams)}/planning`;
}
export function pathSiteMonitoringPerformance(pathParams?: {
  orgId: string;
  siteId: string;
}) {
  return `${pathSiteMonitoring(pathParams)}/performance`;
}
export function pathSiteMonitoringEdit(pathParams?: {
  orgId: string;
  siteId: string;
}) {
  return `${pathSiteMonitoring(pathParams)}/edit`;
}

export function pathSiteMonitoringVms(pathParams?: {
  orgId: string;
  siteId: string;
}) {
  return `${pathSiteMonitoring(pathParams)}/vms`;
}

export function pathSiteMonitoringVmsBoard(pathParams?: {
  orgId: string;
  siteId: string;
  vmsConfigId?: string;
}) {
  return `${pathSiteMonitoringVms(pathParams)}/board/${!pathParams ? ':vmsConfigId' : (pathParams.vmsConfigId ?? '')}`;
}
